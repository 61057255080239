import { IC_APPLE, IC_FACEBOOK, IC_GOOGLE, IC_LINKEDIN, IC_TWITTER } from '@/assets/icons';
import { ClientSafeProvider, getProviders, signIn } from 'next-auth/react';
import { useEffect, useState } from 'react';
import styles from './styles/SocialLogin.module.scss';

const SocialLogin = () => {
	const [providers, setProviders] = useState<Record<string, ClientSafeProvider> | null>(null);

	useEffect(() => {
		const fetchProviders = async () => {
			const res = await getProviders();
			setProviders(res as Record<string, ClientSafeProvider>);
		};

		fetchProviders();
	}, []);
	if (!providers) {
		return;
	}
	const LoginButton = ({ provider }) => {
		switch (provider.id) {
			case 'google':
				return (
					<button onClick={() => signIn(provider.id)}>
						<IC_GOOGLE />
					</button>
				);
			case 'facebook':
				return (
					<button onClick={() => signIn(provider.id)}>
						<IC_FACEBOOK />
					</button>
				);
			case 'twitter':
				return (
					<button onClick={() => signIn(provider.id)}>
						<IC_TWITTER />
					</button>
				);
			case 'apple':
				return (
					<button onClick={() => signIn(provider.id)}>
						<IC_APPLE />
					</button>
				);
			case 'linkedin':
				return (
					<button onClick={() => signIn(provider.id)}>
						<IC_LINKEDIN />
					</button>
				);
			default:
				return null;
		}
	};

	return (
		<div className={styles.social_login_wrapper}>
			<div className={styles.top}>
				<hr />
				<span className='text-grey800'>or</span>
				<hr />
			</div>
			<div className={styles.login_buttons}>
				{Object.values(providers).map((provider) => (
					<div key={provider.name}>
						<LoginButton provider={provider} />
					</div>
				))}
			</div>
		</div>
	);
};

export default SocialLogin;
