'use client';

import PaymentLoading from '@/components/atoms/Loaders/PaymentLoading';
import PaymentSucessModal from '@/components/organisms/PaymentSucessModal';
import UploadPrescriptionModal from '@/components/organisms/UploadPrescriptionModal';
import { useAlert } from '@/contexts/AlertProvider';
import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import { API_ACCOUNT, API_CART } from '@/services/ecommerce/apis/apiConstrant';
import { isCouponExpireStorageName, locationStorageName } from '@/services/ecommerce/apis/config';
import { attempt, isEmpty, isError, map } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAuth from './AuthProvider';
import useLoginModel from './LoginModelProvider';
interface CartContextProps {
	carts: any;
	cartAction: (data: any) => void;
	loadingState: boolean;
	setCarts: (data: any) => void;
	removeCart: (id: number, resetDeliveryOption?: boolean | null) => void;
	getCart: (params?: any) => void;
	drawerOpen: boolean;
	toggleDrawer: () => void;
	showAddressModal: boolean;
	setShowAddressModal: (value: boolean) => void;
	currentlocation: string;
	setDeliveryAddress: (address: any) => void;
	resetDeliveryAddress: (id: number) => void;
	setDrawerOpen: (value: boolean) => void;
	showUploadPrescriptionModal: boolean;
	setShowUploadPrescriptionModal: (value: boolean) => void;
	toggleUploadPrescriptionModal: () => void;
	allRequestedProducts: any;
	setAllRequestedProducts: (value: any) => void;
	removeCartLoading?: boolean;
	isPayementLoading?: boolean;
	setIsPayementLoading?: (value: boolean) => void;
	hasQuantityDecreased?: boolean;
	setHasQuantityDecreased?: any;
	quantityCache?: any;
	hasQuantityDecreasedModal?: any;
	paymentSuccessModal?: boolean;
	setPaymentSuccessModal?: any;
	paymentSuccessModalData?: any;
	setPaymentSuccessModalData?: any;
	orderDataGlobal: any;
	setOrderDataGlobal?: any;
	updateCouponExpire?: any;
	isCouponExpire?: boolean;
}

const CartContext = createContext<CartContextProps>({
	carts: null,
	cartAction: () => {},
	loadingState: false,
	setCarts: () => {},
	removeCart: () => {},
	getCart: () => {},
	drawerOpen: false,
	toggleDrawer: () => {},
	showAddressModal: false,
	setShowAddressModal: () => {},
	currentlocation: null,
	setDeliveryAddress: () => {},
	resetDeliveryAddress: () => {},
	setDrawerOpen: () => {},
	showUploadPrescriptionModal: false,
	setShowUploadPrescriptionModal: () => {},
	toggleUploadPrescriptionModal: () => {},
	allRequestedProducts: [],
	setAllRequestedProducts: () => {},
	removeCartLoading: false,
	isPayementLoading: false,
	setIsPayementLoading: () => {},
	setPaymentSuccessModalData: () => {},
	setPaymentSuccessModal: () => {},
	orderDataGlobal: () => {},
	setOrderDataGlobal: () => {},
	paymentSuccessModal: false,
	paymentSuccessModalData: null,
	updateCouponExpire: () => {},
	isCouponExpire: false
});

interface CartActionProps {
	id: number;
	quantity: number;
	variant: number;
	baseUnit: number;
}

interface CartProviderProps {
	children: React.ReactNode;
}

const CartProvider = ({ children }: CartProviderProps) => {
	const [carts, setCarts] = useState<any>(null);
	const [loadingState, setLoadingState] = useState<boolean>(false);
	const { isLogged } = useAuth();
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const { setShowLoginModal } = useLoginModel();
	const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
	const [currentlocation, setCurrentLocation] = useState<any>(null);
	const [allRequestedProducts, setAllRequestedProducts] = useState<any>([]);
	const [removeCartLoading, setRemoveCartLoading] = useState<boolean>(false);
	const [isPayementLoading, setIsPayementLoading] = useState<boolean>(false);
	const [hasQuantityDecreased, setHasQuantityDecreased] = useState(false);
	const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
	const [paymentSuccessModalData, setPaymentSuccessModalData] = useState(null);
	const [orderDataGlobal, setOrderDataGlobal] = useState<any>(null);
	const [isCouponExpire, setIsCouponExpire] = useState<boolean>(false);

	const quantityCache = new Map();
	const toast = useAlert();
	const [showUploadPrescriptionModal, setShowUploadPrescriptionModal] = useState(false);
	const toggleUploadPrescriptionModal = () => {
		if (isLogged) {
			setShowUploadPrescriptionModal(!showUploadPrescriptionModal);
		} else {
			setShowLoginModal(true);
		}
	};

	const getAllRequestedProducts = async () => {
		const { data }: any = await AroggaAPI.get(API_ACCOUNT.GET_REQUESTED_PRODUCTS, {
			_fields: 'prs_product_variant_id,prs_id',
			_perPage: 500,
			_page: 1
		});
		const verifiedProducts = map(data, 'prs_product_variant_id');
		setAllRequestedProducts(verifiedProducts);
	};

	const getDeliveryAddress = () => {
		const location = localStorage.getItem(locationStorageName);
		const parsedLocation = attempt(JSON.parse, location);
		if (!isError(parsedLocation)) {
			setCurrentLocation(parsedLocation);
		} else {
			localStorage.removeItem(locationStorageName);
		}
	};
	const resetDeliveryAddress = (id) => {
		const location = localStorage.getItem(locationStorageName);
		const parsedLocation = attempt(JSON.parse, location);
		if (!isError(parsedLocation)) {
			if (parsedLocation?.l_id === id) {
				localStorage.removeItem(locationStorageName);
				setCurrentLocation(null);
			}
		} else {
			localStorage.removeItem(locationStorageName);
		}
	};

	const setDeliveryAddress = (address) => {
		setCurrentLocation(address);
		localStorage.setItem(locationStorageName, JSON.stringify(address));
	};

	const toggleDrawer = () => {
		if (isLogged) {
			if (drawerOpen) {
				setDrawerOpen(false);
				return;
			}
			setDrawerOpen(!drawerOpen);
			// const isAddressSet = isEmpty(carts?.uc_address);
			// if (isAddressSet) {
			// 	if (carts?.length === 0) {
			// 		setDrawerOpen(true);
			// 		return;
			// 	}
			// 	setShowAddressModal(true);
			// } else {
			// 	setDrawerOpen(!drawerOpen);
			// }
		} else {
			setShowLoginModal(true);
		}
	};

	function onCartChange() {
		const isSSR = typeof window === 'undefined';
		if (isSSR) return;

		const cartIcon = document.querySelector('.sticky__cart') as HTMLElement;
		cartIcon?.classList.add('bounce__box');
		cartIcon?.addEventListener(
			'animationend',
			() => {
				cartIcon?.classList.remove('bounce__box');
			},
			{ once: true }
		);
	}

	const cartAction = async ({ id, quantity, variant, baseUnit }: CartActionProps) => {
		try {
			const { data, status, message } = await AroggaAPI.post(API_CART.USER_CART_MODIFY, {
				uc_product_id: id,
				uc_variant_id: variant || null,
				uc_unit_id: baseUnit || null,
				uc_qty: quantity,
				_return_details: 1
			});

			if (status === 'success') {
				const checkQuantityChange = carts?.products?.find((item) => item.p_id === id);

				setCarts(data);
				const channel = new BroadcastChannel('cart_channel');
				channel.postMessage(data);
				onCartChange();
				if (checkQuantityChange) {
					if (checkQuantityChange.quantity !== quantity) {
						toast.success({
							message: 'Quantity updated',
							provider: 'toast'
						});
					}
				} else {
					toast.success({
						message: 'Added to your cart',
						provider: 'toast'
					});
				}
			}
			if (status === 'fail') {
				toast.error({
					title: 'Error',
					message: message,
					provider: 'toast'
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const removeCart = async (id: number, name: any, resetDeliveryOption = null) => {
		toast.warning({
			title: 'Remove',
			message: `Are you sure you want to remove ${name} from your cart?`,
			confirmText: 'Yes',
			cancelText: 'No',
			onConfirm: async () => {
				setRemoveCartLoading(true);
				try {
					setLoadingState(true);
					const { status, data, message } = await AroggaAPI.delete(`${API_CART.USER_CART_MODIFY}${id}`, {
						uc_id: id
					});
					if (status === 'success') {
						// let modifiedProducts = [];
						// if(data.unavailable_items?.length > 0){

						// 	if (data?.unavailable_items.length !== 0) {
						// 		modifiedProducts = [...data?.unavailable_items, ...data?.products];
						// 	} else {
						// 		modifiedProducts = data?.products;
						// 	}

						// 	data.products = modifiedProducts;
						// }
						setCarts(data);
						if (data.products.length === 0) {
							if (resetDeliveryOption) {
								setDeliveryAddress('regular');
							}
						}
						toast.success({
							message: 'Removed from your cart',
							provider: 'toast'
						});
						const channel = new BroadcastChannel('cart_channel');
						channel.postMessage(data);
						onCartChange();
						setLoadingState(false);
						setRemoveCartLoading(false);
					}
					if (status === 'fail') {
						toast.error({
							title: 'Error',
							message: message,
							provider: 'toast'
						});
						setLoadingState(false);
					}
				} catch (err) {
					console.log(err);
					setLoadingState(false);
				}
			}
		});
	};

	const getCart = async (params = {}) => {
		setLoadingState(true);
		onCartChange();
		try {
			const { data, status } = await AroggaAPI.get(API_CART.USER_CART_GET, {
				...params,
				_return_details: 1
			});

			if (status === 'fail') {
				setCarts(null);
				setLoadingState(false);
				return;
			}

			setCarts(data); // Assuming setCarts accepts the type of data
			if (isEmpty(data?.uc_address)) {
				localStorage.removeItem(locationStorageName);
				setCurrentLocation(null);
			}
			setLoadingState(false);
		} catch (err) {
			setLoadingState(false);
		}
	};

	const updatePrsContext = async () => {
		const response = await AroggaAPI.get(`${API_ACCOUNT.GET_REQUESTED_PRODUCTS_IDS}`);

		if (response?.status === 'success') {
			setAllRequestedProducts(response?.data);
		} else {
			setAllRequestedProducts([]);
			// toast.error(response?.message ? response?.message : 'Something went wrong!');
		}
	};
	const updateCouponExpire = (newData: boolean) => {
		setIsCouponExpire(newData);
		if (!newData) {
			localStorage.removeItem(isCouponExpireStorageName);
			return;
		}
		localStorage.setItem(isCouponExpireStorageName, JSON.stringify(newData));
	};

	useEffect(() => {
		isLogged && getCart();
		isLogged && getAllRequestedProducts();
		isLogged && updatePrsContext();
		localStorage.getItem(isCouponExpireStorageName) && setIsCouponExpire(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged]);
	useEffect(() => {
		getDeliveryAddress();
	}, []);

	useEffect(() => {
		const channel = new BroadcastChannel('cart_channel');
		channel.onmessage = (event) => {
			setCarts(event.data);
		};
		return () => {
			channel.close();
		};
	}, []);

	useEffect(() => {
		if (carts) {
			if (typeof window === 'undefined') return;
			if (isEmpty(carts?.uc_address)) {
				localStorage.removeItem(locationStorageName);
				setCurrentLocation(null);
			} else {
				setDeliveryAddress(carts?.uc_address);
			}
		}
	}, [carts]);
	// console.log();
	return (
		<CartContext.Provider
			value={{
				carts,
				cartAction,
				loadingState,
				setCarts,
				removeCart,
				getCart,
				drawerOpen,
				toggleDrawer,
				showAddressModal,
				setShowAddressModal,
				currentlocation,
				setDeliveryAddress,
				resetDeliveryAddress,
				setDrawerOpen,
				showUploadPrescriptionModal,
				setShowUploadPrescriptionModal,
				toggleUploadPrescriptionModal,
				allRequestedProducts,
				setAllRequestedProducts,
				removeCartLoading,
				isPayementLoading,
				setIsPayementLoading,
				hasQuantityDecreased,
				setHasQuantityDecreased,
				quantityCache,
				paymentSuccessModal,
				setPaymentSuccessModal,
				paymentSuccessModalData,
				setPaymentSuccessModalData,
				orderDataGlobal,
				setOrderDataGlobal,
				updateCouponExpire,
				isCouponExpire
			}}>
			{children}
			{showUploadPrescriptionModal && (
				<UploadPrescriptionModal
					setOpen={setShowUploadPrescriptionModal}
					open={showUploadPrescriptionModal}
					toggle={toggleUploadPrescriptionModal}
				/>
			)}
			<PaymentSucessModal
				open={paymentSuccessModal}
				setOpen={setPaymentSuccessModal}
				data={paymentSuccessModalData}
			/>

			{isPayementLoading && <PaymentLoading />}
		</CartContext.Provider>
	);
};

export { CartContext, CartProvider };

const useCart = () => {
	const context = useContext(CartContext);
	if (context === undefined) {
		throw new Error('useCart must be used within a CartProvider');
	}
	return context;
};

export default useCart;
